.recommendation {
  width: 100%;
  height: 3.5rem;

  position: fixed;
  bottom: 0; left: 0;
  z-index: 3;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    background: $color-orange;
    transition: background 200ms ease-in-out;

    font-weight: 700;
    font-size: .875rem;
    text-align: center;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background: ($color-orange - 10%);
    }

    span.separator {
      padding: 0 .25rem;
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .recommendation {
    width: auto;
    left: auto;
    right: 2rem;

    a {
      padding: 0 1.5rem;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

@media all and (min-width: $breakpoint-xl) {
  .recommendation {
    width: 9rem;
    height: 9rem;

    right: 1.5rem;
    bottom: 1.5rem;

    a {
      border-radius: 100%;
      align-content: center;

      span.separator {
        display: none;
      }

      span:nth-child(1) {
        display: block;
        flex: 0 0 100%;
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: .5rem;
      }

      span:nth-child(3) {
        display: block;
        flex: 0 0 100%;
        font-size: .75rem;
      }
    }
  }
}
