body.login {
  background-vegetables-gray();
  font-family: $font-sourcecode;

  #login {
    width: 100%;
    max-width: 25rem;
    padding: 10% 1rem 10% 1rem;

    h1 {
      margin-bottom: 1.5rem;

      a {
        width: 100%;
        margin: 0 0;
        padding: 0 0;
        outline: none;
        height: 4rem;
        background: url('../img/logo.png') no-repeat center;
        background-size: 100% auto;
      }
    }
  }

  label {
    font-size: .75rem;
    letter-spacing: .1em;
    line-height: .75rem;
    color: $color-white;
    text-transform: uppercase;
    font-family: $font-montserrat;

    &:not([for="rememberme"]) {
      display: block;
      margin-bottom: 1.5rem;
    }
    &[for="pass1"] {
      margin-bottom: .5rem;
    }
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    border: none;
    outline: none;
    appearance: none;
    margin .5rem 0 0 0;
    border-radius: .25rem;

    padding: .75rem .75rem !important;
    background: $color-white;

    font-weight: 600;
    font-size: .875rem;
    color: $color-dark-gray;
    font-family: $font-sourcecode;

    &::placeholder {
      color: $color-orange;
      text-transform: uppercase;
    }
  }

  #pass-strength-result {
    margin-top: .5rem;
    border: none;
    border-radius: .25rem;
  }

  #loginform
  #resetpassform,
  #lostpasswordform {
    border-radius: 4px;
    padding: 1.7rem 1.5rem;
    background: $color-dark-green;

    p.forgetmenot {
      width: 100%;
      display: block;
      margin-bottom: 1rem;
    }

    p.submit {
      width: 100%;
      display: block;

      .button {
        width: 100%;
        float: none;
      }
    }

    .button {
      border: none;
      box-shadow: none;
      font-weight: 600;
      text-shadow: none;
      text-decoration: none;
      letter-spacing: .125rem;
      background: $color-white;
      color: $color-dark-green;

      &[disabled] {
        text-shadow: none !important;
        background: $color-white !important;
        color: $color-dark-green !important;
      }
    }

    .password-input-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      input {
        width: 85%;
      }

      .button.button-secondary.wp-hide-pw {
        width: 10%;
        display: flex;
        height: 2.5rem;
        align-items: center;
        justify-content: center;
        border-radius: .25rem;
        margin-bottom: 0;
      }
    }
  }

  #nav,
  #backtoblog {
    text-align: center;

    a {
      color: $color-dark-green;
    }
  }

  #login_error {
    border: none;
    padding: 1rem;
    font-size: .875rem;
    color: $color-white;
    line-height: 1.25rem;
    border-radius: .25rem;
    background: $color-red;
    margin-bottom: 1.5rem;

    a {
      color: $color-white;
    }
  }

  .message,
  .description.indicator-hint {
    border: none;
    padding: 1rem;
    font-size: .875rem;
    color: $color-white;
    line-height: 1.25rem;
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    background: $color-blue;

    a {
      color: $color-white;
    }
  }
}

@media all and (min-width: $breakpoint-m) {
  body.login {
    #loginform {
      p.forgetmenot {
        width: auto;
        display: block;
        margin-bottom: 0;
      }

      p.submit {
        width: auto;
        display: block;

        .button {
          width: auto;
          float: right;
        }
      }
    }
  }
}