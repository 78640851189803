.accordion {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 2rem;

  &-title {
    outline: none;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.25rem;
    position: relative;
    color: $color-white;
    padding: .75rem 1rem;
    text-transform: none;
    border-radius: .25rem;
    padding-right: 3.5625rem;
    background: $color-dark-green;
    font-family: $font-montserrat;

    &:after {
      content: '';
      display: block;
      width: 1.5625rem;
      height: 1.5625rem;
      background: url('../img/accordion-toggle.png') no-repeat top;
      background-size: 1.5625rem 3.125rem;

      position: absolute;
      top: 50%; right: 1rem;
      margin-top: -.78125rem;
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:after {
        background-position: bottom;
      }
    }

    &:not(:first-child) {
      margin-top: 1.25rem;
    }
  }

  &-content {
    color: $color-white;
    padding: 0 1rem 1rem;
    background: $color-dark-green;

    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;

    :first-child,
    :last-child {
      display: none;
    }

    :nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  &-content ~ p {
    display: none;
  }
}

@media all and (min-width: $breakpoint-xl) {
  .accordion {
    &-title {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
}
