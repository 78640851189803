// Fonts:
$font-montserrat = 'Montserrat', sans-serif;
$font-sourcecode = 'Source Code Pro', monospace;

// Top / Bottom margins:
$margin-vertical-small = 3rem;                     // 48px
$margin-vertical-medium = 2rem;                    // 32px
$margin-vertical-large = 4rem;                     // 64px

// Side margins:
$margin-horizontal-small = 1rem;                   // 16px
$margin-horizontal-medium = 2rem;                  // 32px
$margin-horizontal-large = 4rem;                   // 64px

// Maximum widths:
$width-small = 33.75rem;                           // 540px
$width-medium = 54.375rem;                         // 870px
$width-large = 70rem;                              // 1120px

// Responsive breakpoints:
$breakpoint-s = 30rem;                             // 480px
$breakpoint-m = 48rem;                             // 768px
$breakpoint-l = 64rem;                             // 1024px
$breakpoint-xl = 75rem;                            // 1200px
$breakpoint-xxl = 90rem;                           // 1440px
$breakpoint-xxxl = 120rem;                         // 1920px

// Gray vegetables background:
background-vegetables-gray() {
  background: $color-white;
  /*background: $color-white url('../img/textures/vegetables-gray.png') repeat;
  background-size: 166px 166px;*/
}

// Green vegetables background:
background-vegetables-green() {
  background: $color-dark-green;
  /*background: $color-dark-green url('../img/textures/vegetables-green.png') repeat;
  background-size: 166px 166px;*/
}
