.page.section {
  background-vegetables-green();

  color: $color-white;

  h1, h2, h3, h4, h5 {
    color: $color-white;
  }

  &.page--gray {
    color: $color-dark-green;
    background-vegetables-gray();

    h1, h2, h3, h4, h5 {
      color: $color-dark-green;
    }
  }

  &.page--green {
    color: $color-white;
    background-vegetables-green();

    h1, h2, h3, h4, h5 {
      color: $color-white;
    }
  }
}

.page {
  &__header {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }
  }

  &__excerpt {
    margin-top: .75rem;
  }

  &__content {
    p {
      img {
        height: auto;
        display: block;
        max-width: 100%;
      }
    }
  }

  &__columns {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
  }

  &__column {
    flex: 0 0 100%;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .page {
    &__header {
      margin-bottom: 3rem;
    }

    &__column {
      flex: 0 0 47%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}
