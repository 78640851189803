.modaal-wrapper {
  .is_loading,
  .modaal-error {
    padding: 2rem 2rem;
    text-align: center;
    color: $color-white;
    line-height: 1.25rem;
    background: $color-dark-green;
  }
}

.modaal-content-container {
  padding: 0;
}

.toplist-modal {
  color: $color-white;
  background: $color-dark-green;

  &__sidebar {
    padding: 2rem;
    text-align: center;
    background: $color-white;
  }

  &__ribbon {
    width: 8rem;
    margin: 0 auto;
    position: relative;

    img {
      height: auto;
      display: block;
      max-width: 100%;
    }
  }

  &__ribbon &__rank {
    display: flex;
    width: 6.25rem;
    height: 6.25rem;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);

    font-size: 2rem;
    font-weight: 900;
    color: $color-dark-green;

    position: absolute;
    top: 1rem; left: 50%;
  }

  &__content {
    padding: 2rem;

    h1 {
      font-size: 1.5rem;
      line-height: 2rem;
      color: $color-white;

      span {
        width: 2.25rem;
        height: 2.25rem;
        align-items: center;
        border-radius: 100%;
        display: inline-flex;
        margin-right: .25rem;
        justify-content: center;
        background: $color-orange;
      }
    }
  }

  &__table {
    width: 100%;
    margin-bottom: 2rem;

    thead th {
      font-weight: 700;
      font-size: 1.125rem;
      text-transform: uppercase;
    }

    th, td {
      padding-top: .25rem;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  &__footer {
    width: 100%;
    text-align: center;
  }

  &__share {
    margin: 0 auto;
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media all and (min-width: 400px) {
  .toplist-modal {
    &__content {
      padding: 3rem;
      font-size: 1.125rem;
    }
  }
}

@media all and (min-width: 640px) {
  .toplist-modal {
    &__content {
      padding: 3rem 4rem;

      h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;

        span {
          width: 3rem;
          height: 3rem;
          font-size: 1.5rem;
          position: relative;
          top: -2px;
        }
      }
    }

    &__table {
      margin-bottom: 3rem;
    }

    &__share {
      margin: 0 auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .toplist-modal {
    display: flex;
    align-items: stretch;
    justify-content: center;

    &__sidebar {
      width: 40%;
      display: flex;
      padding: 4rem 0;
      align-items: center;
      justify-content: center;
    }

    &__ribbon {
      width: 12rem;
    }

    &__ribbon &__rank {
      top: 1.5rem;
      width: 9.375rem;
      height: 9.375rem;

      font-size: 3rem;
    }

    &__content {
      display: flex;
      padding: 4rem 4rem;
      align-items: center;
      align-content: center;

      h1 {
        font-size: 2rem;
        line-height: 3rem;

        span {
          top: -4px;
        }
      }
    }
  }
}
